import { BatteryLevelChip } from '@/components/Chips/BatteryLevelChip';
import { CancelStepDialog } from '@/components/Dialogs/CancelStepDialog';
import { CompleteChecksDialog } from '@/components/Dialogs/CompleteChecksDialog';
import { ConfirmDialog } from '@/components/Dialogs/ConfirmDialog';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { NavigateButton } from '@/components/common/NavigateButton/NavigateButton';
import { useStepInteraction } from '@/hooks/Tour/General/useStepInteraction';
import { useVehicleInteractionOnStep } from '@/hooks/Tour/General/useVehicleInteraction';
import { NavigatorUtils } from '@/utils/NavigatorUtils';
import type {
  CompiledTour,
  CompleteChecksResponse,
  Job,
  Step,
  TransitionAction,
  TriggerAction,
  Vehicle
} from '@badgermoleV2/api';
import { StepState } from '@badgermoleV2/api';
import { unlockSaddle } from '@badgermoleV2/vehicles';

import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerRoundFilled } from 'spinners-react';
import RefreshIcon from '@mui/icons-material/Refresh';

type RelocateVehicleProps = {
  readonly job: Job;
  readonly step: Step;
  readonly vehicle: Vehicle;
  readonly compiledTour: CompiledTour;
  readonly removeVehicleFromTour: (vehicle: Vehicle) => void;
};

export const RelocateVehicle = ({
  compiledTour,
  job,
  step,
  vehicle,
  removeVehicleFromTour
}: RelocateVehicleProps) => {
  const { t } = useTranslation();

  const {
    failStepAsync,
    reloadStepPosition,
    completeStepAsync,
    checkCompleteStepAsync,
    isReloadStepPositionPending,
    isFailStepPending,
    isCheckCompleteStepPending,
    isCompleteStepPending
  } = useStepInteraction();

  const { sendVehicleInteractionAsync } = useVehicleInteractionOnStep();

  const [checksOpen, setChecksOpen] = useState(false);
  const [failStepDialogOpen, setFailStepDialogOpen] = useState(false);
  const [confirmActivateDialogOpen, setConfirmActivateDialogOpen] = useState(false);
  const [completeChecksResponse, setCompleteChecksResponse] = useState<CompleteChecksResponse>();

  const [failProcess, setFailProcess] = useState(false);
  const [selectedCancelAction, setSelectedCancelAction] = useState<TransitionAction>();
  const [cancelReason, setCancelReason] = useState<string>();

  const completeWithChecks = async () => {
    setConfirmActivateDialogOpen(false);
    setChecksOpen(false);
    setFailProcess(false);
    setSelectedCancelAction(undefined);
    setCancelReason(undefined);
    const response = await checkCompleteStepAsync({
      tour: compiledTour,
      step
    });
    if (response.success) {
      await completeAction();
    } else {
      setCompleteChecksResponse(response);
    }
    setChecksOpen(true);
  };

  const failWithChecks = async (cancelAction: TransitionAction, reason: string) => {
    setConfirmActivateDialogOpen(false);
    setChecksOpen(false);
    setFailProcess(true);
    setSelectedCancelAction(cancelAction);
    setCancelReason(reason);
    const response = await checkCompleteStepAsync({
      tour: compiledTour,
      step
    });
    if (response.success) {
      await failAction(cancelAction, reason);
    } else {
      setCompleteChecksResponse(response);
    }
    setChecksOpen(true);
  };

  const failAction = async (cancelAction: TransitionAction, reason: string) => {
    setFailStepDialogOpen(false);
    const response = await failStepAsync({
      tour: compiledTour,
      step,
      cancelAction,
      failReason: reason
    });
    if (response.success) removeVehicleFromTour(vehicle);
  };

  const completeAction = async () => {
    const response = await completeStepAsync({
      tour: compiledTour,
      step
    });
    if (response.success) removeVehicleFromTour(vehicle);
  };

  const [loadingActions, setLoadingActions] = useState<TriggerAction[]>([]);

  const toggleTriggerActionLoading = (action: TriggerAction) => {
    setLoadingActions((actions) =>
      actions.includes(action) ? actions.filter((a) => a !== action) : [...actions, action]
    );
  };

  const loading =
    isReloadStepPositionPending ||
    isFailStepPending ||
    isCheckCompleteStepPending ||
    isCompleteStepPending;
  const stepInteractionDisabled = step.isDoneOrFailed || loading;

  return (
    <>
      {loading && <DataLoadingDialog open={true} message={t('common:loading:general')} />}

      {checksOpen && completeChecksResponse && (
        <CompleteChecksDialog
          allowIgnore
          open={checksOpen}
          tour={compiledTour}
          job={job}
          step={step}
          completeChecksResponse={completeChecksResponse}
          onRetry={() => {
            if (failProcess) {
              failWithChecks(selectedCancelAction!, cancelReason!);
            } else {
              completeWithChecks();
            }
          }}
          onClose={() => {
            setChecksOpen(false);
          }}
          onIgnore={() => {
            setChecksOpen(false);
            if (failProcess && selectedCancelAction && cancelReason) {
              failAction(selectedCancelAction, cancelReason);
            } else {
              completeAction();
            }
          }}
        />
      )}

      {failStepDialogOpen && (
        <CancelStepDialog
          open={failStepDialogOpen}
          handleOk={(cancelAction, reason) => {
            failWithChecks(cancelAction, reason);
          }}
          handleCancel={() => {
            setFailStepDialogOpen(false);
          }}
          cancelAction={step.cancelActions![0]}
        />
      )}

      {confirmActivateDialogOpen && (
        <ConfirmDialog
          open={confirmActivateDialogOpen}
          cancelTitle={t('relocate:dialog:cancelActivate')}
          handleCancel={() => setConfirmActivateDialogOpen(false)}
          handleOk={() => {
            completeWithChecks();
          }}
          okMessage={t('relocate:dialog:activate')}
          okTitle={t('relocate:dialog:activate')}
          hint={t('relocate:dialog:hint')}
          placeholder={undefined}
          withComment={false}
          successAction={true}
          warning={undefined}
        />
      )}

      <Stack
        key={`RelocateListItem${vehicle.vehicleId}`}
        gap={1}
        width="100%"
        sx={{ padding: '.5rem', marginBottom: 1 }}
      >
        <Stack spacing={1} direction="row" alignItems={'center'} justifyContent={'space-between'}>
          <Typography sx={{ fontSize: 'large', fontWeight: 'bold' }}>
            {vehicle.licencePlate}
          </Typography>
          <BatteryLevelChip size="small" batteryLevel={vehicle.batteryLevel} />
          <NavigateButton url={NavigatorUtils.createMapsUrl(vehicle)} />
          <Button
            disabled={loading}
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() => {
              reloadStepPosition({
                tour: compiledTour,
                step
              });
            }}
          >
            <RefreshIcon fontSize="small" />
          </Button>
        </Stack>
        {(step.state === StepState.Canceled || step.state === StepState.Failed) && (
          <Stack spacing={1} direction="row" alignItems={'center'} justifyContent={'center'}>
            <Typography sx={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>
              {t('relocate:state.failed')}
            </Typography>
          </Stack>
        )}
        {step.state === StepState.Completed && (
          <Stack spacing={1} direction="row" alignItems={'center'} justifyContent={'center'}>
            <Typography sx={{ fontSize: 'large', fontWeight: 'bold', color: 'green' }}>
              {t('relocate:state.activated')}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" justifyContent={'space-between'}>
          {(step.triggerActions ?? []).map((triggerAction) => (
            <Button
              key={`CurrentStepTriggerActionButton${triggerAction}`}
              data-testid={`vehicleInteraction${triggerAction}`}
              size="small"
              variant="outlined"
              color="secondary"
              disabled={loadingActions.some((loadingAction) => loadingAction === triggerAction)}
              onClick={async () => {
                toggleTriggerActionLoading(triggerAction);
                await sendVehicleInteractionAsync({
                  missionId: compiledTour.tour.missionId!,
                  tourId: compiledTour.tour.tourId!,
                  stepId: step.stepId!,
                  action: {
                    action: triggerAction
                  }
                });
                toggleTriggerActionLoading(triggerAction);
              }}
            >
              {t(`pickup-tour:triggerActions:${triggerAction}`)}
              {loadingActions.some((loadingAction) => loadingAction === triggerAction) && (
                <SpinnerRoundFilled size={30} thickness={100} speed={100} color="grey" />
              )}
            </Button>
          ))}
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => unlockSaddle(vehicle.vin!)}
          >
            {t('relocate:openSaddle')}
          </Button>
        </Stack>

        {!stepInteractionDisabled && (
          <Stack direction="row" gap={2}>
            <Button
              sx={{ width: '40%' }}
              variant="contained"
              color="error"
              disabled={stepInteractionDisabled}
              fullWidth
              onClick={() => {
                setFailStepDialogOpen(true);
              }}
            >
              {t('fail')}
            </Button>
            <Button
              disabled={stepInteractionDisabled}
              variant="contained"
              color="success"
              fullWidth
              onClick={() => {
                setConfirmActivateDialogOpen(true);
              }}
            >
              {t('pickup-tour:completeActions:' + step.completeAction.toString())}
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  );
};
