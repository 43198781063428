import { MUTATION_KEYS } from '@/config/MutationKeys';
import { QUERY_KEYS } from '@/config/QueryKeys';
import { getServiceAsset, createCommentOnAsset } from '@badgermoleV2/service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useAssetInfo = (vin: string | undefined) => {
  const { data, isFetching, dataUpdatedAt } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.assetInfo, vin],
    queryFn: async ({ signal }) => await getServiceAsset(vin!, true, { signal }),
    staleTime: 1000 * 60 * 5,
    enabled: vin !== undefined
  });

  return {
    asset: data,
    isAssetFetching: isFetching,
    lastUpdatedTime: dataUpdatedAt
  };
};
