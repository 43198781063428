import { MutationErrorHandler, QueryErrorHandler } from '@/error/ErrorHandler';
import { theme } from '@/config';
import { Button, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import 'leaflet/dist/leaflet.css';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/router';

const queryCache = new QueryCache({
  onError: QueryErrorHandler
});

const mutationCache = new MutationCache({
  onError: MutationErrorHandler
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 9 //9hrs
    }
  }
});

export const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

const App = () => {
  return (
    <>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister: localStoragePersister,
              maxAge: 1000 * 60 * 60 * 9 //9hrs
            }}
          >
            <Toaster>
              {(t) => (
                <ToastBar toast={t}>
                  {({ icon, message }) => (
                    <>
                      {icon}
                      {message}
                      {t.type !== 'loading' && (
                        <Button color="secondary" onClick={() => toast.dismiss(t.id)}>
                          X
                        </Button>
                      )}
                    </>
                  )}
                </ToastBar>
              )}
            </Toaster>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
          </PersistQueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
