import { QUERY_KEYS } from '@/config/QueryKeys';
import { getWorkshops } from '@badgermoleV2/workshop';
import { useQuery } from '@tanstack/react-query';

export const useWorkshops = () => {
  const {
    data = [],
    isFetching,
    refetch
  } = useQuery({
    queryKey: [QUERY_KEYS.workshop.getWorkshops],
    queryFn: async ({ signal }) => getWorkshops(undefined, { signal })
  });

  return {
    workshops: data,
    isWorkshopFetching: isFetching,
    refetchWorkshops: refetch
  };
};
