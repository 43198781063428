import { ROUTES } from '@/App/routes/routes';
import { CriticalErrorDialog } from '@/components/Dialogs/CriticalErrorDialog';
import { TourFooter } from '@/components/Tour';
import { useUserProfile } from '@/hooks/Authentication/useUserProfile';
import { useMissions } from '@/hooks/Mission/useMissions';
import { useCityBounds } from '@/hooks/useCityBounds';
import { useTitle } from '@/hooks/useTitle';
import { useUserLocation } from '@/hooks/useUserLocation';
import { OverviewSelectionItem } from '@/screens/OverviewScreen/OverviewSelectionItem';
import { isFeatureFlagInternalWorkshopEnabled } from '@/utils/FeatureFlags';
import { TourUtils } from '@/utils/TourUtils';
import { MissionType } from '@badgermoleV2/api';
import { HomeRepairService, LocalShipping } from '@mui/icons-material';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import HandymanIcon from '@mui/icons-material/Handyman';
import KeyIcon from '@mui/icons-material/Key';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Box, LinearProgress, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const OverviewScreen = () => {
  useTitle({ title: 'common:title' });
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { isOnlyExternalUser, isWorkshopUser } = useUserProfile();

  const hasNoWorkshopAccess = isWorkshopUser();

  const { locationPermission } = useUserLocation('current');

  useCityBounds(isOnlyExternalUser());

  const {
    pickupMission,
    pickupMissionLoading,
    serviceMission,
    serviceMissionLoading,
    customMission,
    customMissionLoading,
    relocateMission,
    relocateMissionLoading,
    isLoadingMissions,
    isLoadingOpenTours,
    openTours
  } = useMissions({
    isOnlyExternal: isOnlyExternalUser(),
    disablePickup: import.meta.env.VITE_DISABLE_PICKUP === 'true'
  });

  const noPickupTourAvailable =
    import.meta.env.VITE_DISABLE_PICKUP === 'true' &&
    TourUtils.tourFeatureDisabled(pickupMission, openTours, MissionType.Pickup);
  const noServiceTourAvailable = TourUtils.tourFeatureDisabled(
    serviceMission,
    openTours,
    MissionType.Swap
  );
  const noCustomTourAvailable = TourUtils.tourFeatureDisabled(
    customMission,
    openTours,
    MissionType.Custom
  );
  const noRelocateTourAvailable = TourUtils.tourFeatureDisabled(
    relocateMission,
    openTours,
    MissionType.Relocate
  );

  if (!locationPermission)
    return <CriticalErrorDialog open={true} error={t('common:geolocation.unavailable')} />;

  if (isOnlyExternalUser())
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, margin: '2rem 2rem 8rem 2rem' }}
      >
        <OverviewSelectionItem
          dataTestId={'externalWorkshop'}
          title={t('external-workshop:title')}
          description={t('external-workshop:description')}
          onClick={() => navigate(ROUTES.WORKSHOP.ROOT)}
          hint={undefined}
          disabledHint={undefined}
          loading={false}
          icon={<HandymanIcon />}
        />
        <TourFooter
          reloadAction={() => {
            window.location.reload();
          }}
        />
      </Box>
    );

  return (
    <List
      sx={{
        margin: '2rem 2rem 8rem 2rem'
      }}
    >
      {(isLoadingMissions || isLoadingOpenTours) && <LinearProgress />}
      <OverviewSelectionItem
        dataTestId="selectUmparken"
        title={t('umparken:title')}
        description={t('umparken:description')}
        onClick={() => navigate(ROUTES.UMPARKEN)}
        icon={<LocalParkingIcon />}
      />
      <OverviewSelectionItem
        dataTestId="selectVehicleReporting"
        title={t('report-vehicle:title')}
        description={t('report-vehicle:description')}
        onClick={() => {
          navigate(ROUTES.REPORTS);
        }}
        icon={<NoteAddIcon />}
      />
      <OverviewSelectionItem
        dataTestId={'selectPickupTour'}
        title={t('pickup-tour:title')}
        description={t('pickup-tour:description')}
        onClick={() => {
          const pickupTour = TourUtils.getTourFromOpenTours(openTours, MissionType.Pickup);
          if (pickupTour) {
            navigate(`${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.RUN}`);
          } else {
            navigate(`${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.PREPARE}`);
          }
        }}
        hint={
          TourUtils.tourRunning(openTours, MissionType.Pickup)
            ? t('pickup-tour:openTourPending')
            : undefined
        }
        disabled={pickupMissionLoading || noPickupTourAvailable}
        disabledHint={noPickupTourAvailable ? t('pickup-tour:currentlyUnavailable') : undefined}
        icon={<LocalShipping />}
      />
      <OverviewSelectionItem
        dataTestId={'selectServiceTour'}
        title={t('service-tour:title')}
        description={t('service-tour:description')}
        onClick={() => {
          const swapTour = TourUtils.getTourFromOpenTours(openTours, MissionType.Swap);
          if (swapTour) {
            navigate(`${ROUTES.SERVICE.ROOT}${ROUTES.TOUR.RUN}`);
          } else {
            navigate(`${ROUTES.SERVICE.ROOT}${ROUTES.TOUR.PREPARE}`);
          }
        }}
        hint={
          TourUtils.tourRunning(openTours, MissionType.Swap)
            ? t('service-tour:openTourPending')
            : undefined
        }
        disabled={serviceMissionLoading || noServiceTourAvailable}
        disabledHint={
          serviceMissionLoading || noServiceTourAvailable
            ? t('pickup-tour:currentlyUnavailable')
            : undefined
        }
        icon={<ElectricalServicesIcon />}
      />
      <OverviewSelectionItem
        dataTestId={'selectCustomTour'}
        title={t('custom-tour:title')}
        description={t('custom-tour:description')}
        onClick={() => {
          const customTour = TourUtils.getTourFromOpenTours(openTours, MissionType.Custom);
          if (customTour) {
            navigate(`${ROUTES.CUSTOM.ROOT}${ROUTES.TOUR.RUN}`);
          }
        }}
        hint={
          TourUtils.tourRunning(openTours, MissionType.Custom)
            ? t('custom-tour:openTourPending')
            : undefined
        }
        disabled={customMissionLoading || noCustomTourAvailable}
        disabledHint={
          customMissionLoading || noCustomTourAvailable
            ? t('pickup-tour:currentlyUnavailable')
            : undefined
        }
        icon={<KeyIcon />}
      />
      <OverviewSelectionItem
        dataTestId={'selectRelocateTour'}
        title={t('relocate:title')}
        description={t('relocate:description')}
        onClick={() => {
          navigate(`${ROUTES.RELOCATE}${ROUTES.TOUR.RUN}`);
        }}
        hint={
          TourUtils.tourRunning(openTours, MissionType.Relocate)
            ? t('relocate:openTourPending')
            : undefined
        }
        disabled={relocateMissionLoading || noRelocateTourAvailable}
        disabledHint={
          relocateMissionLoading || noRelocateTourAvailable
            ? t('pickup-tour:currentlyUnavailable')
            : undefined
        }
        icon={<CarRepairIcon />}
      />
      {isFeatureFlagInternalWorkshopEnabled && (
        <OverviewSelectionItem
          title={t('workshop:title')}
          description={t('workshop:description')}
          onClick={() => {
            navigate(`${ROUTES.WORKSHOP.ROOT}`);
          }}
          icon={<HomeRepairService />}
        />
      )}
      <TourFooter
        reloadAction={() => {
          window.location.reload();
        }}
      />
    </List>
  );
};
