import { MUTATION_KEYS } from '@/config/MutationKeys';
import { useEmployees } from '@/hooks/useEmployees';
import { PersonSearch } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type AssigneeModalContentProps = {
  currentAssignee: string;
  currentBusinessLocation: number;
  onConfirm: (assignee: string) => Promise<void>;
  close: () => void;
};
export const AssigneeModalContent = ({
  close,
  currentAssignee,
  currentBusinessLocation,
  onConfirm
}: AssigneeModalContentProps) => {
  const { t } = useTranslation();
  const { employees } = useEmployees({
    businessLocationId: currentBusinessLocation,
    userRole: 'tasks-without-jira'
  });
  const [selectedAssignee, setSelectedAssignee] = useState(currentAssignee);
  const isUpdateAssigneePending = Boolean(
    useIsMutating({
      mutationKey: [MUTATION_KEYS.repair.updateAssignee]
    })
  );

  return (
    <>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h2" fontWeight="bold">
          {t('workshop:repair:modal.assignee.title')}
        </Typography>
        {isUpdateAssigneePending && <CircularProgress />}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ marginTop: 1 }}>
          <InputLabel>{t('workshop:repair:modal.assignee.label')}</InputLabel>
          <Select
            label={t('workshop:repair:modal.assignee.label')}
            startAdornment={<PersonSearch />}
            value={selectedAssignee}
            onChange={(e) => setSelectedAssignee(e.target.value)}
          >
            <MenuItem value={''}>None</MenuItem>
            {employees.map((emp) => (
              <MenuItem key={emp.username} value={emp.username}>
                {emp.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          disabled={isUpdateAssigneePending}
          variant="outlined"
          color="primary"
          onClick={close}
        >
          {t('common:buttons.cancel')}
        </Button>
        <Button
          disabled={
            isUpdateAssigneePending ||
            selectedAssignee === currentAssignee ||
            selectedAssignee === ''
          }
          variant="outlined"
          color="secondary"
          onClick={async () => {
            await onConfirm(selectedAssignee);
            close();
          }}
        >
          {t('workshop:repair:modal.assignee.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};
