import { BatteryLevelChip } from '@/components/Chips/BatteryLevelChip';
import { NavigateButton } from '@/components/common/NavigateButton/NavigateButton';
import type { VehilceWithDistance } from '@/utils/MapUtils';
import { NavigatorUtils } from '@/utils/NavigatorUtils';
import type { Vehicle } from '@badgermoleV2/api';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type AvailableRelocateVehiclesProps = {
  readonly vehicles: VehilceWithDistance[];
  readonly adaptTour: (vehicle: Vehicle[]) => void;
  readonly adaptTourLoading: boolean;
  readonly fetchRelocateVehiclesLoading: boolean;
};

export const AvailableRelocateVehicles = ({
  vehicles,
  adaptTour,
  adaptTourLoading,
  fetchRelocateVehiclesLoading
}: AvailableRelocateVehiclesProps) => {
  const [t] = useTranslation('relocate');

  const [selectedVehicles, setSelectedVehicles] = useState<Vehicle[]>([]);
  const [filter, setFilter] = useState<string>('');

  const filteredVehicles = vehicles.filter((v) => {
    return filter === '' || v.vehicle.licencePlate.toUpperCase().includes(filter.toUpperCase());
  });

  const handleToggle = (other: Vehicle) => {
    setSelectedVehicles((prev) =>
      prev.some((vehicle) => vehicle.vehicleId === other.vehicleId)
        ? prev.filter((vehicle) => vehicle.vehicleId !== other.vehicleId)
        : [...prev, other]
    );
  };

  if (!fetchRelocateVehiclesLoading && vehicles.length === 0)
    return (
      <Alert severity={'warning'} sx={{ margin: '8px' }}>
        {t('noRelocateVehiclesAvailable')}
      </Alert>
    );

  return (
    <Box>
      {(fetchRelocateVehiclesLoading || adaptTourLoading) && <LinearProgress />}
      <TextField
        id="license-plate-search"
        data-testid="licensePlateSearch"
        label={t('search')}
        size="small"
        fullWidth
        sx={{ marginBottom: '0.2rem' }}
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      />
      <Button
        color="secondary"
        fullWidth
        variant="outlined"
        disabled={adaptTourLoading || fetchRelocateVehiclesLoading || selectedVehicles.length === 0}
        onClick={() => {
          adaptTour(selectedVehicles);
          setSelectedVehicles([]);
        }}
      >
        {t('relocate:addVehicles')}
      </Button>
      <List>
        {filteredVehicles.map(({ vehicle, distanceToUser: distance }) => (
          <ListItem
            key={`AvailableVehicle${vehicle.licencePlate}`}
            disablePadding
            disabled={fetchRelocateVehiclesLoading || adaptTourLoading}
            secondaryAction={
              <NavigateButton
                url={NavigatorUtils.createMapsUrl(vehicle)}
                iconButtonProps={{ disabled: fetchRelocateVehiclesLoading || adaptTourLoading }}
              />
            }
          >
            <ListItemButton onClick={() => handleToggle(vehicle)} dense divider>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedVehicles.some((other) => vehicle.vehicleId === other.vehicleId)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary={`${vehicle.licencePlate}`}
                secondary={`${distance.toFixed(2)}km`}
              />

              <BatteryLevelChip
                style={{ marginRight: '1em' }}
                size="small"
                batteryLevel={vehicle.batteryLevel}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
